import React from 'react';
import { useEffect, useState } from 'react';
import {
  createSpecialOffers,
  createToolboxOffers,
  getHotelOffer,
  updateHotelOffersPage,
  updateTask,
  saveTask,
  updateToolboxOffersPage,
  getToolboxOffer,
  getOfferCategoriesAndTypes,
  dataServiceStepBack,
  getPricingDropdown,
  copyOffer,
} from '../../../service/api';
import { Button } from '../../atoms/Button';
import { Actions } from '../Actions';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { routes as RootRoute } from '../../../config/routes';
import { Header } from '../Header';
import { useDispatch, useSelector } from 'react-redux';
import { Nav } from './nav';
import './root.css';
import { ShowAuthInfoModal } from '../../../pages/advancedBooking/authInfo';
import {
  handleChange,
  setDataServiceToDefault,
  setOfferStateToDefault,
} from '../../../store/offerToolSlice';
import Alert from '../../atoms/Alert';
import { HelpPanel } from '../Help';
import { useHelpText } from '../../../hooks/useHelpText';
import { CrossIcon } from '../../Icons/CrossIcon';
import { store } from '../../../store/store';
import featureFlag from '../../../config/featureFlags';
import { submitOffer } from '../../../service/api';
import { ThunkDispatch } from '@reduxjs/toolkit';
import {
  extractOfferId,
  extractSpiritCode,
  extractOfferCategory,
  extractPath,
} from '../../../utils/fileUtils';
import { getCookie } from '../../../utils/cookieUtils';
import { getDaysBetweenDates } from '../../../utils/dateAndTimeUtils';
import { v4 as uuid } from 'uuid';
import { isDSAssociate, isDSMarketing } from '../../../utils/userUtils';
import { isHtmlPresent, validateRTEText } from '../../../utils/textUtils';

interface ResponseParam {
  status: 'success' | 'error' | 'warning';
  title: string;
  message: string;
}
interface InclusionItem {
  inclusionDetails: string;
  inclusionText: string;
}
export const Root = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [, clearHelpText] = useHelpText();

  const { pathname } = useLocation();
  const pagePath = extractPath(useLocation().search);

  const [RequestFailedAlert, setRequestFailedAlert] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [requestStatus, setRequestStatus] = useState<ResponseParam>({
    status: 'success',
    title: '',
    message: '',
  });
  const data = useSelector((state: any) => state.offerTool);
  const {
    offers: {
      offerCategory: storeOfferCategory,
      offerType,
      spiritCode: storeSpiritCode,
      attachments,
      cancellationAndCodes: { offerCodes },
      dataservices: { directSell, otherRatePlans },
    },
    offerCodeState,
    dsDirectSalesState,
    dsOtherRatePlaneState,
    dsAssignToState,
    incultionState,
    dataServiceStepBackReason,
    dataServiceActionReason,
    showRejectOfferScreen,
    showAcceptOfferScreen,
    showStepBackOfferScreen,
    page,
    isUpdate,
    isCopyOffer,
    isAddHotelChian,
    fromReviewAndSubmit,
    isRequestFailed,
    workflowItemId,
    isBackRoutesValueExists,
    isAttachmentLoading,
    spiritCodeToBeAdded,
    stepBackOptionLoading,
    isLaunchOffer,
  } = useSelector((state: any) => state.offerTool);
  const { createOfferId } = useSelector((state: any) => state.offerTool);

  const [showSubmitOfferErrorAlert, setShowSubmitOfferErrorAlert] = useState(false);
  const [dataServiceRequestFailed, setDataServiceRequestFailed] = useState(false);

  const location = useLocation();
  const urlOfferId = extractOfferId(location.search);
  const urlSpiritCode = extractSpiritCode(location.search);
  const urlOfferCategory = extractOfferCategory(location.search);

  const offerPath = extractPath(location.search);
  const offerId = urlOfferId ? urlOfferId : createOfferId;
  const offerCategory = urlOfferCategory ? urlOfferCategory : storeOfferCategory;
  const spiritCode = urlSpiritCode ? urlSpiritCode : storeSpiritCode[0];
  const [showAdvancedBookingModal, setShowAdvancedBookingModal] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const disableSubmit = store.getState().offerTool.disableSubmit;
    setDisableSubmit(disableSubmit);
  }, [pathname, data]);

  const getOfferPath = (): string => {
    const { recordType } = store.getState().offerTool.offers;
    let offerPath;
    if (offerCategory === 'hotel-special-offer') {
      if (recordType === 'Chain') {
        offerPath = `?offerPath=/content/offers/hotels/global/${offerId}`;
      } else {
        offerPath = `?offerPath=/content/offers/hotels/${spiritCode}/${offerId}`;
      }
    } else {
      if (recordType === 'Chain') {
        offerPath = `?offerPath=/content/offers/hotels/global/toolbox/${offerId}`;
      } else {
        offerPath = `?offerPath=/content/offers/hotels/${spiritCode}/toolbox/${offerId}`;
      }
    }
    return offerPath;
  };

  const next = (skipPages: number = 0) => {
    clearHelpText('');
    const nextPage = page + skipPages + 1;
    dispatch(handleChange({ name: 'page', value: nextPage }));
    const pages = RootRoute?.routes?.[offerCategory === 'toolbox-offer' ? 2 : 1]?.children || [];
    if (page === 0 || page === 1) {
      const path = `${pages?.[nextPage - 1]?.path}`;
      navigate(path);
    } else if (page >= 2) {
      const path = `${pages?.[nextPage - 1]?.path}` + getOfferPath();
      navigate(path);
    } else {
      navigate(`${pages?.[nextPage - 1]?.path}`);
    }
  };

  const resetInclutionAndBlackoutState = () => {
    if (page === 3 && data.incultionState !== '') {
      dispatch(
        handleChange({
          name: 'incultionState',
          value: {
            inclusionDetails: '',
            inclusionText: '',
            inclusionFrequency: '',
          },
        })
      );
    }
    if (page === 6 && data.blackoutState !== '') {
      dispatch(
        handleChange({
          name: 'blackoutState',
          value: {
            blackoutRange: '',
            blackoutStartDate: undefined,
            blackoutEndDate: undefined,
          },
        })
      );
    }
  };

  const back = () => {
    clearHelpText('');
    dispatch(handleChange({ name: 'showError', value: false }));
    if (page === 2 && data.basicsAdditionalEmail !== '') {
      dispatch(handleChange({ name: 'basicsAdditionalEmail', value: '' }));
    }
    if (offerCategory !== 'toolbox-offer') {
      resetInclutionAndBlackoutState();
      if (isCopyOffer) {
        switch (page) {
          case 6:
            dispatch(handleChange({ name: 'disableNext', value: true }));
            break;
          case 13:
            backPage(5);
            break;
          default:
            backPage();
        }
      } else {
        switch (page) {
          case 6:
            backPage(offerType === 'hyattpriveluxury-offer' || offerType === 'hyatt-prive' ? 1 : 0);
            break;
          case 8:
            backPage(offerType === 'hyattpriveluxury-offer' ? 1 : 0);
            break;
          case 9:
              backPage(offerType === 'hyatt-prive' ? 1 : 0);
              break;
          case 12:
            backPage((data.offers.pricing.qualifiers === 'qualifiedCustomers' && !data.offers.distributionChannel.hyattCom) || data.offers.offerType === 'hyatt-luxury-consortia'
              || (offerType === 'hyatt-prive' && !data.offers.distributionChannel.priveWebsite) ? 1 : 0);
            break;
          default:
            backPage();
        }
      }
    } else if (offerCategory === 'toolbox-offer') {
      switch (page) {
        case 5:
          backPage((offerType === 'hyatt-prive-amex-luxury-consortia-discount-toolbox' || offerType === 'woh-bonus-points-toolbox-offer') ? 1 : 0);
          break;
        default:
          backPage();
      }
    }
  };

  const backPage = (noOfBack: number = 0): void => {
    const prevPage = page - 1 - noOfBack;
    if (prevPage <= 0) {
      navigate(`/welcome/${offerId}`);
    } else {
      const pages = RootRoute?.routes?.[offerCategory === 'toolbox-offer' ? 2 : 1]?.children || [];
      const path = `${pages?.[prevPage - 1]?.path}` + getOfferPath();
      navigate(path);
    }
    dispatch(handleChange({ name: 'page', value: page - 1 - noOfBack }));
  };

  const updateOffer = async () => {
    const currentOffer = store.getState().offerTool.offers;
    dispatch(handleChange({ name: 'isRequestLoading', value: true }));
    if (offerCategory === 'hotel-special-offer') {
      const resp = await updateHotelOffersPage(currentOffer, offerId, pagePath, currentOffer.offerId);
      setIsUpdated(true);
      if (resp.status === 200) {
        onEditCancel();
        dispatch(handleChange({ name: 'isRequestLoading', value: false }));
        setRequestStatus({
          status: 'success',
          message: 'The offer has beed updated.',
          title: 'Changes Saved',
        });
        setTimeout(async () => {
          await dispatch(
            getHotelOffer({
              path: pagePath ? pagePath : offerPath,
            })
          );
        }, 1000);
      } else {
        dispatch(handleChange({ name: 'isRequestLoading', value: false }));
        setRequestStatus({
          status: 'error',
          message: resp.status + ' ' + resp.statusText,
          title: 'Failed',
        });
      }
    } else {
      const resp = await updateToolboxOffersPage(currentOffer, offerId, pagePath, currentOffer.offerId);
      setIsUpdated(true);
      if (resp.status === 200) {
        onEditCancel();
        dispatch(handleChange({ name: 'isRequestLoading', value: false }));
        setRequestStatus({
          status: 'success',
          message: 'The offer has beed updated.',
          title: 'Changes Saved',
        });
        setTimeout(() => {
          dispatch(getToolboxOffer({ path: pagePath }));
        }, 1000);
      } else {
        dispatch(handleChange({ name: 'isRequestLoading', value: false }));
        setRequestStatus({
          status: 'error',
          message: `${resp.status} Failed to update the offer.`,
          title: 'Failed',
        });
      }
    }
  };

  const checkValidation = (isNext?: boolean, isUpdate?: boolean) => {
    const {
      page: pageIs,
      offers: {
        pricing: { offerRack, qualifiers },
      },
      blackoutState,
    } = store.getState().offerTool;
    const todayDate = new Date();
    switch (pageIs) {
      case 0: ///welcome'
        if (data.offers?.offerCategory === '' || data.offers?.offerCategory === 'all') {
          isNext && dispatch(handleChange({ name: 'showError', value: true }));
        } else {
          dispatch(handleChange({ name: 'showError', value: false }));
          isNext && goToNextPage();
        }
        break;
      case 1: ///promotions
        if (data.offers?.offerType === '') {
          isNext && dispatch(handleChange({ name: 'showError', value: true }));
        } else {
          dispatch(handleChange({ name: 'showError', value: false }));
          isNext && goToNextPage();
        }
        break;
      case 2: //basics
        const { basicsAdditionalEmail, isCopyOffer } = store.getState().offerTool;
        if (
          (data.offers.offerCategory === 'hotel-special-offer' &&
            data.offers.promotionName === '') ||
          data.offers.spiritCode.length === 0
        ) {
          (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
        } else if (data.offers.recordType === "Chain" &&
          data.offers.spiritCode.length === 1){
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
        } else if (basicsAdditionalEmail !== '') {
          const { email } = { email: basicsAdditionalEmail };
          const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
          if (!emailRegex.test(basicsAdditionalEmail)) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
            return;
          }
          const isDuplicateEmail = data.offers?.additionalEmails?.filter(
            (e: string) => email === basicsAdditionalEmail
          );
          if (isDuplicateEmail?.length === 0) {
            (isNext || isUpdate) &&
              dispatch(
                handleChange({
                  name: 'additionalEmails',
                  value: [...data.offers?.additionalEmails, email],
                })
              );
            (isNext || isUpdate) &&
              dispatch(handleChange({ name: 'basicsAdditionalEmail', value: '' }));
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: false }));
            if (isNext) {
              goToNextPage();
            }
            if (isUpdate) {
              if (isCopyOffer && !fromReviewAndSubmit) {
                goToNextPage(3);
              } else {
                updateOffer();
              }
            }
          } else {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          }
        } else {
          dispatch(handleChange({ name: 'showError', value: false }));
          if (isNext) {
            goToNextPage();
          }
          if (isUpdate) {
            if (isCopyOffer && !fromReviewAndSubmit) {
              goToNextPage(3);
            } else {
              updateOffer();
            }
          }
        }
        break;
      case 3:
        if (offerCategory === 'toolbox-offer') {
          let pageHasErrors = false;

          if (offerType === 'htb-percent-off') {
            if (
              data.offers?.pricing?.qualifiers === '' ||
              data.offers.bookingDayLeadTime === '' ||
              data.offers.bookingDayLeadTime < 1
            ) {
              pageHasErrors = true;
            }
          }

          if (offerType === 'htb-rack-plus-attribute') {
            if (data.offers?.pricing?.qualifiers === '') {
              pageHasErrors = true;
            }
          }

          if (pageHasErrors) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else {
            dispatch(handleChange({ name: 'showError', value: false }));
            //toolbox inclusions
            if (isNext) {
              if (offerType === 'hyatt-prive-amex-luxury-consortia-discount-toolbox' || offerType === 'woh-bonus-points-toolbox-offer') {
                goToNextPage(1);
              }
              else {
                goToNextPage();
              }
            }
            if (isUpdate) {
              updateOffer();
            }
          }
        } else {
          if (offerType === 'percent-off-offer') {
            if (
              data.offers.pricing.offerRack === '' ||
              data.offers.pricing.offerTypeValue === '' ||
              data.offers.pricing.qualifiers === ''
            ) {
              if (isNext || isUpdate) {
                dispatch(handleChange({ name: 'showError', value: true }));
              }
            } else {
              dispatch(handleChange({ name: 'showError', value: false }));
              if (isNext) {
                goToNextPage();
              }
              if (isUpdate) {
                updateOffer();
              }
            }
          } else if (offerType === 'hyatt-prive' || offerType === 'hyatt-luxury-consortia') {
            if (
              data.offers.offerType === 'hyatt-prive' && data.offers.pricing.qualifiers === ''
            ) {
              if (isNext || isUpdate) {
                dispatch(handleChange({ name: 'showError', value: true }));
              }
            } else {
              dispatch(handleChange({ name: 'showError', value: false }));
              if (isNext) {
                goToNextPage();
              }
              if (isUpdate) {
                updateOffer();
              }
            }
          } else {
            if (data.offers.pricing.offerRack === '') {
              (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
            } else if (data.offers.pricing.qualifiers === '') {
              (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
            } else {
              dispatch(handleChange({ name: 'showError', value: false }));
              if (isNext) {
                goToNextPage();
              }
              if (isUpdate) {
                updateOffer();
              }
            }
          }
        }
        break;
      case 4:
        if (offerCategory !== 'toolbox-offer') {
          //Code block commented out for WOH points hide flag
          // if (offerTypeExclution.includes(offerType)) {
          if (
            (incultionState?.inclusionText && !incultionState?.inclusionFrequency) ||
            (incultionState?.inclusionFrequency && !incultionState?.inclusionText)
          ) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else {
            dispatch(handleChange({ name: 'showError', value: false }));
            if (isNext) {
              editCurrentOfferWhileDestorying('hotelInclusion');
              if (offerType === 'hyattpriveluxury-offer' || offerType === 'hyatt-prive') {
                goToNextPage(1);
              } else {
                goToNextPage();
              }
            }
            if (isUpdate) {
              editCurrentOfferWhileDestorying('hotelInclusion');
              updateOffer();
            }
          }
        } else {
          //toolbox incultions
          const inclusions = data.offers?.inclusions;
          const hotelCreditInclusion = inclusions.find((item: InclusionItem) => item.inclusionText === 'hotel-credit');
          if (
            hotelCreditInclusion && hotelCreditInclusion.inclusionDetails === ''
          ) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: false }));
            if (isNext) {
              goToNextPage();
            }
            if (isUpdate) {
              updateOffer();
            }
          }
        }
        break;
      case 5:
        if (offerCategory !== 'toolbox-offer') {
          // pricing-details
          if (data.offers?.advancedBooking.advancedBookingEligibility === null) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else if (
            data.offers?.advancedBooking.advancedBookingEligibility &&
            data.offers?.advancedBooking.advancedBookingDays === ''
          ) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else {
            dispatch(handleChange({ name: 'showError', value: false }));
            if (isNext) {
              goToNextPage();
            }
            if (isUpdate) {
              updateOffer();
            }
          }
        } else {
          //toolbox booking-start-and-end-dates
          const { bookingStartDate, bookingEndDate, stayStartDate, stayEndDate } =
            data?.offers?.bookingDates;
          let startDategreateThenEnd = true;
          let startNotWithinLimit = true;
          let bookingStartDategreateThenEnd = true;
          let bookingStartNotWithinLimit = true;
          let stayStartAndEndDatesAreSame = true;
          let stayEndDateAfterBookingEnd = true;
          let bookingDateWindowValidationFail = false;
          if (stayStartDate || stayEndDate || bookingStartDate || bookingEndDate) {
            const stayStartDateObj = new Date(stayStartDate);
            const stayEndDateObj = new Date(stayEndDate);
            startDategreateThenEnd = getDaysBetweenDates(stayStartDateObj, stayEndDateObj) < 0;
            stayStartAndEndDatesAreSame =
              getDaysBetweenDates(stayStartDateObj, stayEndDateObj) === 0;
            stayEndDateAfterBookingEnd = getDaysBetweenDates(new Date(stayEndDate), new Date(bookingEndDate)) > 0;
            startNotWithinLimit = isDSAssociate() || isDSMarketing() ? false : getDaysBetweenDates(todayDate, stayStartDateObj) < 5;
            if (startDategreateThenEnd || startNotWithinLimit || stayStartAndEndDatesAreSame) {
              (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
            } else if (!bookingStartDate || !bookingEndDate) {
              (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
            } else if (bookingStartDate && bookingEndDate) {
              const bookingStartDateObj = new Date(bookingStartDate);
              const bookingEndDateObj = new Date(bookingEndDate);
              bookingStartDategreateThenEnd =
                getDaysBetweenDates(bookingStartDateObj, bookingEndDateObj) < 1;
              bookingStartNotWithinLimit = isDSAssociate() || isDSMarketing() ? false : getDaysBetweenDates(todayDate, bookingStartDateObj) < 5;
              bookingDateWindowValidationFail =
                getDaysBetweenDates(bookingStartDateObj, bookingEndDateObj) > 90;
              const stayStartbeforeBookingStart =
                getDaysBetweenDates(stayStartDateObj, bookingStartDateObj) > 0;
              if (
                bookingStartDategreateThenEnd ||
                bookingStartNotWithinLimit ||
                stayStartbeforeBookingStart ||
                bookingDateWindowValidationFail ||
                stayEndDateAfterBookingEnd
              ) {
                (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
              } else if (!stayStartDate || !stayEndDate) {
                (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
              } else {
                dispatch(handleChange({ name: 'showError', value: false }));
                if (isNext) {
                  goToNextPage();
                }
                if (isUpdate) {
                  updateOffer();
                }
              }
            } else {
              dispatch(handleChange({ name: 'showError', value: false }));
              if (isNext) {
                goToNextPage();
              }
              if (isUpdate) {
                updateOffer();
              }
            }
          } else {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          }
        }
        break;
      case 6:
        if (offerCategory === 'toolbox-offer') {
          if (data.offerCodeState !== '' && data.offerCodeState.length < 4) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else if (
            data.offers.cancellationAndCodes.depositPolicy === '' ||
            data.offers.cancellationAndCodes.penaltyPolicy === '' ||
            data.offers.cancellationAndCodes.cancelPolicy === '' ||
            data.offers.cancellationAndCodes.cancellationPolicy === ''
          ) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else {
            dispatch(handleChange({ name: 'showError', value: false }));
            if (isNext) {
              editCurrentOfferWhileDestorying('offercode');
              goToNextPage();
            }
            if (isUpdate) {
              editCurrentOfferWhileDestorying('offercode');
              updateOffer();
            }
          }
        } else {
          const { isCopyOffer } = store.getState().offerTool;
          //booking-start-and-end-dates
          let startDategreateThenEnd = true;
          let startNotWithinLimit = true;
          let bookingStartDategreateThenEnd = true;
          let bookingStartNotWithinLimit = true;
          let stayStartAndEndDatesAreSame = true;
          let bookingDateWindowValidationFail = false;
          let stayEndDateAfterBookingEnd = true;
          const {
            bookingStartDate,
            bookingEndDate,
            stayStartDate,
            stayEndDate,
            updateDates
          } = data?.offers?.bookingDates;

          if (
            stayStartDate ||
            stayEndDate ||
            bookingStartDate ||
            bookingEndDate ||
            updateDates !== null
          ) {
            const stayStartDateObj = new Date(stayStartDate);
            const stayEndDateObj = new Date(stayEndDate);
            stayEndDateAfterBookingEnd = getDaysBetweenDates(new Date(stayEndDate), new Date(bookingEndDate)) > 0;
            startDategreateThenEnd = getDaysBetweenDates(stayStartDateObj, stayEndDateObj) < 0;
            stayStartAndEndDatesAreSame =
              getDaysBetweenDates(stayStartDateObj, stayEndDateObj) === 0;
            startNotWithinLimit = isDSAssociate() || isDSMarketing() ? false : getDaysBetweenDates(todayDate, stayStartDateObj) < 15;
            if (startDategreateThenEnd || startNotWithinLimit || stayStartAndEndDatesAreSame) {
              (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
            } else if (!bookingStartDate || !bookingEndDate) {
              (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
            } else if (bookingStartDate && bookingEndDate) {
              const bookingStartDateObj = new Date(bookingStartDate);
              const bookingEndDateObj = new Date(bookingEndDate);
              bookingStartDategreateThenEnd =
                getDaysBetweenDates(bookingStartDateObj, bookingEndDateObj) < 1;
              bookingStartNotWithinLimit = isDSAssociate() || isDSMarketing() ? false : getDaysBetweenDates(todayDate, bookingStartDateObj) < 15;
              bookingDateWindowValidationFail =
                offerRack === 'less-than-rack' &&
                qualifiers === 'nonQualifiedCustomers' &&
                getDaysBetweenDates(bookingStartDateObj, bookingEndDateObj) > 90;
              const stayBeforeBookingStart =
                getDaysBetweenDates(stayStartDateObj, bookingStartDateObj) > 0;
              if (
                bookingStartDategreateThenEnd ||
                bookingStartNotWithinLimit ||
                stayBeforeBookingStart ||
                bookingDateWindowValidationFail ||
                stayEndDateAfterBookingEnd
              ) {
                (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
              } else if (!stayStartDate || !stayEndDate) {
                (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
              } else {
                dispatch(handleChange({ name: 'showError', value: false }));
                if (isNext) {
                  if (offerType === 'hyattpriveluxury-offer') {
                    goToNextPage(1);
                    return;
                  }else{
                    goToNextPage();
                  }
                }
                if (isUpdate) {
                  if (isCopyOffer) {
                    if (offerType === 'hyattpriveluxury-offer') {
                      goToNextPage(6);
                      return;
                    }else{
                      goToNextPage();
                    }
                  } else {
                    updateOffer();
                  }
                }
              }
            } else {
              dispatch(handleChange({ name: 'showError', value: false }));
              if (isNext) {
                goToNextPage();
              }
              if (isUpdate) {
                if (isCopyOffer) {
                  goToNextPage();
                } else {
                  updateOffer();
                }
              }
            }
          } else {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          }
        }
        break;
      case 7:
        if (offerCategory !== 'toolbox-offer') {
          if (
            ((blackoutState.blackoutStartDate || blackoutState.blackoutEndDate) &&
              blackoutState.blackoutRange === '') ||
            (blackoutState.blackoutRange === 'date-range' &&
              (!blackoutState.blackoutStartDate || !blackoutState.blackoutEndDate) &&
              (isNext || isUpdate)) ||
            (blackoutState.blackoutRange === 'single-day' &&
              !blackoutState.blackoutStartDate &&
              (isNext || isUpdate))
          ) {
            dispatch(handleChange({ name: 'showError', value: true }));
          } else if (
            !(
              (blackoutState.blackoutStartDate || blackoutState.blackoutEndDate) &&
              blackoutState.blackoutRange === ''
            ) &&
            !(
              blackoutState.blackoutRange === 'date-range' &&
              (!blackoutState.blackoutStartDate || !blackoutState.blackoutEndDate)
            ) &&
            !(blackoutState.blackoutRange === 'single-day' && !blackoutState.blackoutStartDate)
          ) {
            if (isNext) {
              dispatch(handleChange({ name: 'showError', value: false }));
              editCurrentOfferWhileDestorying('hotelBlackoutDays');
              if (offerType === 'hyatt-prive') {
                goToNextPage(1);
              }
              else {
                goToNextPage();
              }
            } else if (isUpdate) {
              dispatch(handleChange({ name: 'showError', value: false }));
              editCurrentOfferWhileDestorying('hotelBlackoutDays');
              if (data.isCopyOffer) {
                goToNextPage(5);
              } else {
                updateOffer();
              }
            } else {
              dispatch(handleChange({ name: 'showError', value: false }));
            }
          }
        } else {
          if (data?.offers?.offerType === 'hyatt-prive-amex-luxury-consortia-discount-toolbox') {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = data.offers?.websiteOffers?.offerDescription;
            if (data.offers.websiteOffers?.isOffer &&
              (data.offers?.websiteOffers?.offerTitle === '' ||
                tempDiv.textContent?.trim() === '' ||
                tempDiv.textContent?.trim() === 'undefined' ||
                !data.offers.websiteOffers.validBrandManagerID ||
                !data.offers?.websiteOffers?.brandManagerID ||
                data.offers?.websiteOffers?.brandManagerID === '')
            ) {
              (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
            }
            else {
              dispatch(handleChange({ name: 'showError', value: false }));
              if (isNext) {
                goToNextPage();
              }
              if (isUpdate) {
                updateOffer();
              }
            }
          }
          else if (data?.offers?.offerType === 'woh-bonus-points-toolbox-offer') {
            if (data.offers.websiteOffers?.isOffer &&
              (!data.offers.websiteOffers.validBrandManagerID ||
                !data.offers?.websiteOffers?.brandManagerID ||
                data.offers?.websiteOffers?.brandManagerID === '')) {
              (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
            }
            else {
              dispatch(handleChange({ name: 'showError', value: false }));
              if (isNext) {
                goToNextPage();
              }
              if (isUpdate) {
                updateOffer();
              }
            }
          }
          else if (
            !data.offers.websiteOffers.validBrandManagerID ||
            (data.offers.websiteOffers?.isOffer &&
              data.offers.websiteOffers?.changeOfferTitle &&
              (data.offers.websiteOffers?.offerTitle === '' ||
                data.offers.websiteOffers.brandManagerID === ''))
          ) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else {
            dispatch(handleChange({ name: 'showError', value: false }));
            if (isNext) {
              goToNextPage();
            }
            if (isUpdate) {
              updateOffer();
            }
          }
        }
        break;
      case 8:
        if (offerCategory !== 'toolbox-offer') {
          // valid-days-of-week
          if (data.offers?.offerValidity?.validDays?.length === 0) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else {
            dispatch(handleChange({ name: 'showError', value: false }));
            if (isNext) {
              goToNextPage();
            }
            if (isUpdate) {
              updateOffer();
            }
          }
        } else {
          //Toolbox attachment
          if (attachments?.rdrmfile === '' && !featureFlag.hideRDRMTranslation) {
            if (isNext || isUpdate) {
              dispatch(handleChange({ name: 'showError', value: true }));
            }
          } else {
            dispatch(handleChange({ name: 'showError', value: false }));
            if (isNext) {
              goToNextPage();
            }
            if (isUpdate) {
              updateOffer();
            }
          }
        }
        break;
      case 9:
        if (offerCategory !== 'toolbox-offer') {
          // offer-code
          if (data.offerCodeState !== '' && data.offerCodeState.length < 4) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else if (
            data.offers.cancellationAndCodes.depositPolicy === '' ||
            data.offers.cancellationAndCodes.penaltyPolicy === '' ||
            data.offers.cancellationAndCodes.cancelPolicy === '' ||
            data.offers.cancellationAndCodes.cancellationPolicy === ''
          ) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else {
            dispatch(handleChange({ name: 'showError', value: false }));
            if (isNext) {
              editCurrentOfferWhileDestorying('offercode');
              goToNextPage();
            }
            if (isUpdate) {
              editCurrentOfferWhileDestorying('offercode');
              updateOffer();
            }
          }
        } else {
          // toolbox review-and-submit
          dispatch(handleChange({ name: 'showError', value: false }));
          if (isNext) {
            goToNextPage();
          }
          if (isUpdate) {
            updateOffer();
          }
          //TODO::
        }
        break;
      case 10:
        if (offerCategory === 'toolbox-offer') {
          //TODO::
        } else {
          // distribution-channels
          const channel = data.offers.distributionChannel;
          if (offerType === 'hyatt-prive' && !channel.priveWebsite && (isNext)) {
            goToNextPage(1);
          }
          else if (
            channel.wholesaleConnected &&
            channel.wholesaleConnectedSpecificPartner?.length === 0 &&
            data.DistributionChennalcheckedData.wholeSaleConnectedValue === ''
          ) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else if (
            channel.wholesaleNotConnected &&
            channel.wholesaleNotConnectedSpecificPartner.length === 0 &&
            data.DistributionChennalcheckedData.wholeSaleNotConnectedValue === ''
          ) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else if (
            channel.otaBundled &&
            channel.otaBundledSpecificPartner.length === 0 &&
            data.DistributionChennalcheckedData.otaBundledValue === ''
          ) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else if (
            channel.luxuryConsortiaPartners &&
            channel.luxuryConsortiaPartnersSpecificPartner.length === 0 &&
            data.DistributionChennalcheckedData.luxuryValue === ''
          ) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else if (
            channel.gds ||
            channel.hyattCom ||
            channel.luxuryConsortiaPartners ||
            channel.otaBundled ||
            channel.otaStandalone ||
            channel.voiceAndHotelDirect ||
            channel.wholesaleConnected ||
            channel.wholesaleNotConnected
          ) {
            dispatch(handleChange({ name: 'showError', value: false }));
            if (isNext) {
              editCurrentOfferWhileDestorying('hotelDistributionChennal');
                goToNextPage((data.offers.pricing.qualifiers === 'qualifiedCustomers' && !channel.hyattCom) || data.offers.offerType === 'hyatt-luxury-consortia' ? 1 : 0);
            }
            if (isUpdate) {
              editCurrentOfferWhileDestorying('hotelDistributionChennal');
              updateOffer();
            }
          } else {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          }
        }
        break;
      case 11:
        if (offerCategory === 'toolbox-offer') {
          //TODO::
        } else {
          // property-website
          if (
            data.offers.websiteOffers.isOffer &&
            (data.offers.websiteOffers.offerTitle === '' ||
              !data.offers.websiteOffers.validBrandManagerID ||
              data.offers.websiteOffers.offerDescription === '' ||
              (data.offers?.offerType !== 'hyatt-prive' && data.offers.websiteOffers.brandManagerID === '') ||
              (data.offers.websiteOffers.languages.length === 0 &&
                data.offers.websiteOffers.translationRequired) ||
              isHtmlPresent(data.offers.websiteOffers.offerTitle) ||
              !validateRTEText(data.offers.websiteOffers.offerDescription))
          ) {
            (isNext || isUpdate) && dispatch(handleChange({ name: 'showError', value: true }));
          } else {
            dispatch(handleChange({ name: 'showError', value: false }));
            if (isNext) {
              goToNextPage();
            }
            if (isUpdate) {
              updateOffer();
            }
          }
        }
        break;
      case 12:
        if (offerCategory === 'toolbox-offer') {
          //TODO::
        } else {
          // attachments
          if (attachments?.rdrmfile === '' && !featureFlag.hideRDRMTranslation) {
            if (isNext || isUpdate) {
              dispatch(handleChange({ name: 'showError', value: true }));
            }
          } else {
            dispatch(handleChange({ name: 'showError', value: false }));
            if (isNext) {
              goToNextPage();
            }
            if (isUpdate) {
              updateOffer();
            }
          }
        }
        break;
      case 13:
        if (offerCategory !== 'toolbox-offer') {
          if (isNext) {
            dispatch(handleChange({ name: 'showError', value: false }));
            goToNextPage();
          }
          if (isUpdate) {
            dispatch(handleChange({ name: 'showError', value: false }));
            updateOffer();
          }
        }
        break;
      default:
        console.log('At default something wrong ', pageIs, data);
    }
  };

  const checkDataServiceValidation = () => {
    const { offerCodeState } = store.getState().offerTool;
    if (offerCodeState !== '' && offerCodeState.length < 4) {
      dispatch(handleChange({ name: 'showDataServiceError', value: true }));
      dispatch(handleChange({ name: 'showAcceptOfferScreen', value: false }));
      dispatch(handleChange({ name: 'dataServiceActiveTabIndex', value: 2 }));
    } else {
      dispatch(handleChange({ name: 'showDataServiceError', value: false }));
      dispatch(handleChange({ name: 'showAcceptOfferScreen', value: true }));
    }
  };

  useEffect(() => {
    checkValidation(false);
  }, [data]);

  useEffect(() => {
    const { isUpdate, page: pageIs } = store.getState().offerTool;
    const navigationEntry = performance.getEntriesByType(
      'navigation'
    )[0] as PerformanceNavigationTiming;
    if (navigationEntry && navigationEntry.type === 'reload') {
      if (
        (offerId && isUpdate && location.pathname.includes('basics')) ||
        (offerId && pageIs >= 3)
      ) {
        dispatch(getOfferCategoriesAndTypes());
        dispatch(handleChange({ name: 'isRequestLoading', value: true }));
        if (offerCategory === 'toolbox-offer') {
          const storedLikeToAdd = JSON.parse(getCookie('likeToAdd') || '[]');
          dispatch(handleChange({ name: 'likeToAdd', value: storedLikeToAdd }));
          dispatch(getToolboxOffer({ path: pagePath })).then(({ meta }) => {
            dispatch(handleChange({ name: 'isRequestLoading', value: false }));
            if (meta.requestStatus === 'rejected') {
              dispatch(
                handleChange({
                  name: 'isRequestFailed',
                  value: {
                    isFailed: true,
                    reason: 'No data found for resource given identifier.',
                  },
                })
              );
            }
          });
        } else {
          if(offerPath){
            dispatch(
              getHotelOffer({
                path: offerPath,
              })
            )
              .then(({ meta }) => {
                dispatch(handleChange({ name: 'isRequestLoading', value: false }));
                if (meta.requestStatus === 'rejected') {
                  dispatch(
                    handleChange({
                      name: 'isRequestFailed',
                      value: {
                        isFailed: true,
                        reason: 'No data found for resource given identifier.',
                      },
                    })
                  );
                }
              })
              .then(() => {
                if (offerType === 'hyattpriveluxury-offer') {
                  dispatch(getPricingDropdown('luxury-consortia-partners-types'));
                } else if (offerType === 'package-inclusions-offer') {
                  dispatch(getPricingDropdown('offer-rack-details-types'));
                }
              });
          }else {
            dispatch(handleChange({ name: 'isRequestLoading', value: false }));
          }
        }
      }
    }
  }, []);

  const goToNextPage = async (pageNumber?: number) => {
    const { isCopyOffer } = store.getState().offerTool;

    const pages = RootRoute?.routes?.[offerCategory === 'toolbox-offer' ? 2 : 1]?.children || [];
    if (page < pages.length) {
      if (page === 2 && !fromReviewAndSubmit && !isCopyOffer) {
        dispatch(handleChange({ name: 'isRequestLoading', value: true }));
        if (offerCategory === 'hotel-special-offer') {
          const createResp = await createSpecialOffers(data.offers, offerId);
          if (createResp.status === 200 && offerType) {
            dispatch(handleChange({ name: 'isRequestLoading', value: false }));
            const updateResp = await updateOfferPage();
            if (updateResp === '1') {
              next(pageNumber);
            }
          } else {
            dispatch(handleChange({ name: 'isRequestLoading', value: false }));
            dispatch(handleChange({ name: 'disableNext', value: true }));
            dispatch(
              handleChange({
                name: 'isRequestFailed',
                value: {
                  isFailed: true,
                  reason:
                    createResp.status +
                    ' ' +
                    createResp.statusText +
                    '. Please try again or return later.',
                },
              })
            );
            setRequestFailedAlert(true);
          }
        } else {
          //toolbox-offer
          createToolboxOffers(data.offers, offerId).then(async ({ status, statusText }) => {
            if (status === 200 && offerType) {
              dispatch(handleChange({ name: 'isRequestLoading', value: false }));
              const updateResp = await updateOfferPage();
              if (updateResp === '1') {
                next(pageNumber);
              }
            } else {
              dispatch(handleChange({ name: 'isRequestLoading', value: false }));
              dispatch(handleChange({ name: 'disableNext', value: true }));
              dispatch(
                handleChange({
                  name: 'isRequestFailed',
                  value: {
                    isFailed: true,
                    reason: statusText + '. Please try again or return later.',
                  },
                })
              );
              setRequestFailedAlert(true);
            }
          });
        }
      } else {
        if (page >= 3 || fromReviewAndSubmit || (isCopyOffer && page === 2)) {
          if (
            page === 4 &&
            !data.isAuthroziedToCreateOffer &&
            offerCategory === 'hotel-special-offer' &&
            data.offers.advancedBooking.hyattBonusPoints
          ) {
            setShowAdvancedBookingModal(true);
          } else {
            if (isCopyOffer && page === 2) {
              dispatch(handleChange({ name: 'isRequestLoading', value: true }));
              const uid = uuid();
              const containingPagePath = offerPath;
              let modifiedUrl = containingPagePath.split('/').slice(0, -2).join('/') + '/';
              let destinationPath =
                data.offers.recordType === 'Chain'
                  ? '/content/offers/hotels/global'
                  : modifiedUrl + data.offers.spiritCode;
              const resp = await copyOffer({
                sourcePath: containingPagePath,
                destinationPath: destinationPath,
                newOfferName: uid,
                additionalEmails: data.offers.additionalEmails,
                hotelNames: data.offers.hotelName,
                spiritCodes: data.offers.spiritCode,
                promotionName: data.offers.promotionName,
              });
              if (resp) {
                let offerPath:string;
                if (data.offers.recordType === 'Chain') {
                  offerPath = `/content/offers/hotels/global/${uid}`;
                } else {
                  offerPath = `/content/offers/hotels/${data.offers.spiritCode}/${uid}`;
                }
                setTimeout(async () => {
                  const isCopied = await dispatch(
                    getHotelOffer({
                      path: offerPath,
                    })
                  );
                  if(isCopied){
                    dispatch(handleChange({ name: 'isRequestLoading', value: false }));
                    const nextPage = page + pageNumber + 1;
                    dispatch(handleChange({ name: 'page', value: nextPage }));
                    const path = `${pages?.[nextPage - 1]?.path}` + `?offerPath=${offerPath}`;
                    navigate(path);
                  }
                }, 1000);
              }
            } else {
              const updateResp = await updateOfferPage();
              if (updateResp === '1') {
                next(pageNumber);
              } else {
                dispatch(handleChange({ name: 'isRequestLoading', value: false }));
                dispatch(handleChange({ name: 'disableNext', value: true }));
                dispatch(
                  handleChange({
                    name: 'isRequestFailed',
                    value: {
                      isFailed: true,
                      reason: 'Please try again or return later.',
                    },
                  })
                );
                setRequestFailedAlert(true);
              }
              dispatch(handleChange({ name: 'fromReviewAndSubmit', value: false }));
            }
          }
        }
        if (page === 1 && !offerCategory) {
          return;
        } else if (!isCopyOffer) {
          if (
            page === 4 &&
            !data.isAuthroziedToCreateOffer &&
            offerCategory === 'hotel-special-offer' &&
            data.offers.advancedBooking.hyattBonusPoints
          ) {
          } else {
            next(pageNumber);
          }
        }
      }
    }
  };

  const handleSave = () => {
    if (page >= 3) {
      updateOfferPage();
    }
    dispatch(handleChange({ name: 'page', value: 0 }));
    dispatch(setOfferStateToDefault());
    navigate('/');
  };

  const handleNextFromModal = () => {
    setShowAdvancedBookingModal(false);
    goToNextPage();
  };

  useEffect(() => {
    data.isRequestFailed?.isFailed &&
      setTimeout(() => {
        setRequestFailedAlert(false);
      }, 3000);
  }, [data.isRequestFailed?.isFailed]);

  useEffect(() => {
    isUpdated &&
      setTimeout(() => {
        setIsUpdated(false);
      }, 3000);
  }, [isUpdated]);

  const onEditCancel = () => {
    dispatch(handleChange({ name: 'isUpdate', value: false }));
    navigate(-1);
  };

  const onChainHotelCancel = () => {
    if (spiritCodeToBeAdded?.length === 0) {
      dispatch(handleChange({ name: 'hotelChain', value: [] }));
    }
    dispatch(handleChange({ name: 'isAddHotelChian', value: false }));
  };

  const getThePageNumberAndSendToValidation = async () => {
    const pages =
      RootRoute?.routes?.[offerCategory === 'hotel-special-offer' ? 1 : 2]?.children || [];
    const searchPath = pathname.replace(/\/[a-f0-9-]+$/, '/:pageId');
    const currentPath = pages.findIndex((e) => e.path === searchPath);
    dispatch(handleChange({ name: 'page', value: currentPath + 1 }));
    //get the uuid from url
    dispatch(handleChange({ name: 'createOfferId', value: offerId }));
    checkValidation(false, true);
  };

  useEffect(() => {
    showSubmitOfferErrorAlert &&
      setTimeout(() => {
        setShowSubmitOfferErrorAlert(false);
      }, 3000);
  }, [showSubmitOfferErrorAlert]);

  useEffect(() => {
    dataServiceRequestFailed &&
      setTimeout(() => {
        setDataServiceRequestFailed(false);
      }, 3000);
  }, [dataServiceRequestFailed]);

  const pathIs = extractPath(useLocation().search);

  const successUrl = `../success-submit?offerPath=${pathIs}`;
  const acceptUrl = `../accept-offer?offerPath=${pathIs}`;
  const rejectUrl = `../reject-offer?offerPath=${pathIs}`;

  const routeChange = () => {
    let path = successUrl;
    navigate(path);
  };

  const acceptRouteChange = () => {
    let path = acceptUrl;
    navigate(path);
  };

  const rejecrRouteChange = () => {
    let path = rejectUrl;
    navigate(path);
  };

  const handleSubmitOffer = () => {
    dispatch(handleChange({ name: 'isRequestLoading', value: true }));
    submitOffer(offerPath).then(({ status }) => {
      dispatch(handleChange({ name: 'isRequestLoading', value: false }));
      if (status === 201) {
        routeChange();
      } else {
        setShowSubmitOfferErrorAlert(true);
      }
    });
  };

  const updateOfferPage = async () => {
    const currentOffer = store.getState().offerTool.offers;
    if (offerCategory === 'hotel-special-offer') {
      dispatch(handleChange({ name: 'isRequestLoading', value: true }));
      const resp = await updateHotelOffersPage(currentOffer, offerId, pagePath, currentOffer.offerId);
      dispatch(handleChange({ name: 'isRequestLoading', value: false }));
      if (resp.status !== 200) {
        dispatch(handleChange({ name: 'isRequestLoading', value: false }));
        dispatch(handleChange({ name: 'disableNext', value: true }));
        dispatch(
          handleChange({
            name: 'isRequestFailed',
            value: {
              isFailed: true,
              reason: resp.status + ' ' + resp.statusText + '. Please try again or return later.',
            },
          })
        );
        setRequestFailedAlert(true);
        return '0';
      } else {
        return '1';
      }
    } else {
      //offer tool
      dispatch(handleChange({ name: 'isRequestLoading', value: true }));
      const resp = await updateToolboxOffersPage(currentOffer, offerId, pagePath, currentOffer.offerId);
      dispatch(handleChange({ name: 'isRequestLoading', value: false }));
      if (resp.status !== 200) {
        dispatch(
          handleChange({
            name: 'isRequestFailed',
            value: {
              isFailed: true,
              reason: resp.status + ' ' + resp.statusText,
            },
          })
        );
        setRequestFailedAlert(true);
        return '0';
      } else {
        return '1';
      }
    }
  };

  const resetDataServiceStore = () => {
    dispatch(setDataServiceToDefault());
  };

  const handleDataServiceOfferCode = () => {
    if (offerCodeState || offerCodeState.length > 4) {
      dispatch(handleChange({ name: 'offerCodes', value: [...offerCodes, offerCodeState] }));
      dispatch(handleChange({ name: 'offerCodeState', value: '' }));
    }
  };

  const addDataServiceDirectSales = () => {
    if (
      dsDirectSalesState &&
      dsDirectSalesState?.market &&
      dsDirectSalesState?.category &&
      dsDirectSalesState?.ratePlanCode
    ) {
      dispatch(handleChange({ name: 'dsDirectSell', value: [...directSell, dsDirectSalesState] }));
    }
    dispatch(
      handleChange({
        name: 'dsDirectSalesState',
        value: {
          market: 'market',
          category: '',
          ratePlanCode: '',
        },
      })
    );
  };

  const addOtherDataServiceRatePlans = () => {
    if (
      dsOtherRatePlaneState &&
      dsOtherRatePlaneState?.market &&
      dsOtherRatePlaneState?.category &&
      dsOtherRatePlaneState?.ratePlanCode
    ) {
      dispatch(
        handleChange({
          name: 'dsOtherRatePlans',
          value: [...otherRatePlans, dsOtherRatePlaneState],
        })
      );
    }
    dispatch(
      handleChange({
        name: 'dsOtherRatePlaneState',
        value: {
          market: 'market',
          category: '',
          ratePlanCode: '',
        },
      })
    );
  };

  const onSaveOffer = async () => {
    handleDataServiceOfferCode();
    addDataServiceDirectSales();
    addOtherDataServiceRatePlans();
    const {
      offers,
      offers: { dataservices, containingPagePath },
      dataServiceNotes,
    } = store.getState().offerTool;
    if (offerId && spiritCode) {
      dispatch(handleChange({ name: 'isSavingDraft', value: true }));
      const resp = await saveTask(
        offers,
        offerPath,
        dataservices,
        dataServiceNotes,
        containingPagePath
      );
      dispatch(handleChange({ name: 'isSavingDraft', value: false }));
      if (resp) {
        resetDataServiceStore();
        navigate('/');
      } else {
        setDataServiceRequestFailed(true);
      }
    }
  };

  const onCancelAcceptOffer = () => {
    dispatch(handleChange({ name: 'showAcceptOfferScreen', value: false }));
  };

  const onConfirmAcceptOffer = async () => {
    dispatch(handleChange({ name: 'acceptError', value: false }));
    handleDataServiceOfferCode();
    addDataServiceDirectSales();
    addOtherDataServiceRatePlans();
    const {
      offers,
      offers: { dataservices, containingPagePath },
      dataServiceActionReason,
      dataServiceAssignee,
      dataServiceNotes,
    } = store.getState().offerTool;
    if (offerId && spiritCode) {
      dispatch(handleChange({ name: 'isRequestLoading', value: true }));
      const resp = await updateTask(
        offers,
        offerPath,
        dataservices,
        dataServiceNotes,
        dataServiceActionReason,
        'accept',
        dataServiceAssignee,
        workflowItemId,
        containingPagePath
      );
      dispatch(handleChange({ name: 'isRequestLoading', value: false }));
      if (resp === '1') {
        resetDataServiceStore();
        acceptRouteChange();
      } else {
        setDataServiceRequestFailed(true);
      }
    }
  };

  const onRejectOffer = () => {
    dispatch(handleChange({ name: 'showRejectOfferScreen', value: true }));
  };

  const onCancelRejectOffer = () => {
    dispatch(handleChange({ name: 'showRejectOfferScreen', value: false }));
  };

  const onConfirmRejectWithReason = async () => {
    if (dataServiceActionReason === '') {
      dispatch(handleChange({ name: 'rejectError', value: true }));
    } else {
      dispatch(handleChange({ name: 'rejectError', value: false }));
      handleDataServiceOfferCode();
      addDataServiceDirectSales();
      addOtherDataServiceRatePlans();
      if (offerId && spiritCode) {
        const {
          offers,
          offers: { dataservices, containingPagePath },
          dataServiceActionReason,
          dataServiceAssignee,
          dataServiceNotes,
        } = store.getState().offerTool;
        dispatch(handleChange({ name: 'isRequestLoading', value: true }));
        const resp = await updateTask(
          offers,
          offerPath,
          dataservices,
          dataServiceNotes,
          dataServiceActionReason,
          'reject',
          dataServiceAssignee,
          workflowItemId,
          containingPagePath
        );
        dispatch(handleChange({ name: 'isRequestLoading', value: false }));
        if (resp === '1') {
          dispatch(handleChange({ name: 'showRejectOfferScreen', value: false }));
          resetDataServiceStore();
          rejecrRouteChange();
        } else {
          setDataServiceRequestFailed(true);
        }
      }
    }
  };

  const onStepBackOffer = () => {
    dispatch(handleChange({ name: 'showStepBackOfferScreen', value: true }));
  };

  const onCancelStepBackOffer = () => {
    dispatch(handleChange({ name: 'showStepBackOfferScreen', value: false }));
  };

  const onConfirmStepBackOffer = async () => {
    if (dataServiceStepBackReason === '' || dsAssignToState === '') {
      dispatch(handleChange({ name: 'stepBackError', value: true }));
    } else {
      dispatch(handleChange({ name: 'stepBackError', value: false }));
      handleDataServiceOfferCode();
      addDataServiceDirectSales();
      addOtherDataServiceRatePlans();
      const { stepBackAssignee, dataServiceStepBackReason, dataServiceAssignee } = store.getState().offerTool;
      if (offerId && spiritCode) {
        dispatch(handleChange({ name: 'isRequestLoading', value: true }));
        const resp = await dataServiceStepBack(
          offerPath,
          dataServiceStepBackReason,
          workflowItemId,
          stepBackAssignee,
          dataServiceAssignee,
        );
        dispatch(handleChange({ name: 'isRequestLoading', value: false }));
        if (resp === '1') {
          dispatch(handleChange({ name: 'showRejectOfferScreen', value: false }));
          resetDataServiceStore();
          navigate('/');
        } else {
          setDataServiceRequestFailed(true);
        }
      }
    }
  };

  const editCurrentOfferWhileDestorying = (screen: string) => {
    switch (screen) {
      case 'hotelInclusion':
        const currentInclutions = store.getState().offerTool.offers.inclusions;
        const storeObj = store.getState().offerTool.incultionState;
        if (currentInclutions?.length < 10) {
          if (storeObj.inclusionText !== '' && storeObj.inclusionFrequency !== '') {
            dispatch(handleChange({ name: 'inclusions', value: [...currentInclutions, storeObj] }));
            dispatch(
              handleChange({
                name: 'incultionState',
                value: {
                  inclusionDetails: '',
                  inclusionText: '',
                  inclusionFrequency: '',
                },
              })
            );
          } else {
            dispatch(handleChange({ name: 'inclusions', value: currentInclutions }));
          }
        }
        if (currentInclutions?.length === 10) {
          dispatch(
            handleChange({
              name: 'incultionState',
              value: {
                inclusionDetails: '',
                inclusionText: '',
                inclusionFrequency: '',
              },
            })
          );
        }
        break;
      case 'hotelDistributionChennal':
        const distributionChennalcheckedDataStoreObj =
          store.getState().offerTool.DistributionChennalcheckedData;
        const distributionChennalObj = store.getState().offerTool.offers.distributionChannel;
        if (distributionChennalcheckedDataStoreObj.wholeSaleConnectedValue !== '') {
          dispatch(
            handleChange({
              name: 'wholesaleConnectedSpecificPartner',
              value: [
                ...distributionChennalObj.wholesaleConnectedSpecificPartner,
                distributionChennalcheckedDataStoreObj.wholeSaleConnectedValue,
              ],
            })
          );
          dispatch(
            handleChange({
              name: 'DistributionChennalcheckedData',
              value: { ...distributionChennalcheckedDataStoreObj, ['wholeSaleConnectedValue']: '' },
            })
          );
        }
        if (distributionChennalcheckedDataStoreObj.wholeSaleNotConnectedValue !== '') {
          dispatch(
            handleChange({
              name: 'wholesaleNotConnectedSpecificPartner',
              value: [
                ...distributionChennalObj.wholesaleNotConnectedSpecificPartner,
                distributionChennalcheckedDataStoreObj.wholeSaleNotConnectedValue,
              ],
            })
          );
          dispatch(
            handleChange({
              name: 'DistributionChennalcheckedData',
              value: {
                ...distributionChennalcheckedDataStoreObj,
                ['wholeSaleNotConnectedValue']: '',
              },
            })
          );
        }
        if (distributionChennalcheckedDataStoreObj.otaBundledValue !== '') {
          dispatch(
            handleChange({
              name: 'otaBundledSpecificPartner',
              value: [
                ...distributionChennalObj.otaBundledSpecificPartner,
                distributionChennalcheckedDataStoreObj.otaBundledValue,
              ],
            })
          );
          dispatch(
            handleChange({
              name: 'DistributionChennalcheckedData',
              value: { ...distributionChennalcheckedDataStoreObj, ['otaBundledValue']: '' },
            })
          );
        }
        if (distributionChennalcheckedDataStoreObj.luxuryValue !== '') {
          dispatch(
            handleChange({
              name: 'luxuryConsortiaPartnersSpecificPartner',
              value: [
                ...distributionChennalObj.luxuryConsortiaPartnersSpecificPartner,
                distributionChennalcheckedDataStoreObj.luxuryValue,
              ],
            })
          );
          dispatch(
            handleChange({
              name: 'DistributionChennalcheckedData',
              value: { ...distributionChennalcheckedDataStoreObj, ['luxuryValue']: '' },
            })
          );
        }
        break;
      case 'hotelBlackoutDays':
        const blackoutState = store.getState().offerTool.blackoutState;
        if (
          (blackoutState.blackoutRange === 'date-range' &&
            (!blackoutState.blackoutStartDate || !blackoutState.blackoutEndDate)) ||
          (blackoutState.blackoutRange === 'single-day' && !blackoutState.blackoutStartDate) ||
          blackoutState.blackoutRange === ''
        ) {
          dispatch(
            handleChange({
              name: 'blackoutState',
              value: {
                blackoutRange: '',
                blackoutStartDate: undefined,
                blackoutEndDate: undefined,
              },
            })
          );
        } else {
          dispatch(handleChange({ name: 'blackoutDates', value: blackoutState }));
          dispatch(
            handleChange({
              name: 'blackoutState',
              value: {
                blackoutRange: '',
                blackoutStartDate: undefined,
                blackoutEndDate: undefined,
              },
            })
          );
        }
        break;
      case 'offercode':
        const offerCodeStoreObj = store.getState().offerTool.offerCodeState;
        const offersCodes = store.getState().offerTool.offers.cancellationAndCodes.offerCodes;
        const newOfferCode = offerCodeStoreObj;
        if (offerCodeStoreObj !== '' && offerCodeStoreObj.length >= 4) {
          dispatch(handleChange({ name: 'offerCodes', value: [...offersCodes, newOfferCode] }));
          dispatch(handleChange({ name: 'offerCodeState', value: '' }));
        }
        break;
      default:
        break;
    }
  };
  const pages =
    RootRoute?.routes?.[offerCategory === 'hotel-special-offer' ? 1 : 2]?.children || [];
  const pageClass = ` ${pathname === '/' ? 'dashboard' : ''}`;

  const isToolboxRackAttributeOffer = (): boolean => {
    if (offerCategory === 'toolbox-offer' && data.offers?.offerType === 'htb-rack-plus-attribute') {
      return true;
    } else {
      return false;
    }
  };


  return (
    <div>
      {!isUpdate && !isAddHotelChian && <Header appName="Special Offer Tool" pathname={pathname} />}
      <div className={`mainRoot ${pageClass}`}>
        {pathname === '/' || pathname === '/reporting' ? (
          <div className="navbar">
            <Nav />
          </div>
        ) : null}
        <div className={`root-container ${pageClass}`}>
          {showSubmitOfferErrorAlert && (
            <Alert
              message={'Failed To Submit Offer'}
              title="Offer Submit Failed"
              type="error"
              onClose={() => {
                setShowSubmitOfferErrorAlert(false);
              }}
            />
          )}
          {dataServiceRequestFailed && (
            <Alert
              message={'Failed to update the data service'}
              title="Request Failed"
              type="error"
              onClose={() => {
                setRequestFailedAlert(false);
              }}
            />
          )}
          {RequestFailedAlert && (
            <Alert
              message={'(' + data.isRequestFailed.reason + ')' + ' Please try again in a while'}
              title="Request Failed"
              type="error"
              onClose={() => {
                setRequestFailedAlert(false);
              }}
            />
          )}
          {isUpdated && (
            <Alert
              message={requestStatus.message}
              title={requestStatus.title}
              type={requestStatus.status}
              onClose={() => {
                setIsUpdated(false);
              }}
            />
          )}
          {(isUpdate || isAddHotelChian) && (
            <div className="editCloseButton" onClick={isUpdate ? onEditCancel : onChainHotelCancel}>
              <CrossIcon />
              CLOSE
            </div>
          )}
          <Outlet />
          {showAdvancedBookingModal && (
            <ShowAuthInfoModal
              goToNextPage={handleNextFromModal}
              handleClose={() => {
                setShowAdvancedBookingModal(false);
              }}
            />
          )}
          {data.selectedHelpText ? <HelpPanel /> : null}
          {pathname !== '/reporting' &&
            pathname !== '/' &&
            pathname !== '/success-submit' &&
            pathname !== '/view-offer' &&
            pathname !== '/reject-offer' &&
            pathname !== '/accept-offer' &&
            !isAddHotelChian && (
              <Actions className="footer-nav">
                {!isUpdate ? (
                  <Button
                    id='root-save-and-exit'
                    dataAttribute='root-save-and-exit-button'
                    className="save-btn"
                    label={isLaunchOffer ? 'Save Edits & Exit' : page > 2 ? 'Save Draft & Exit' : 'Exit'}
                    type="link"
                    onClick={handleSave}
                  />
                ) : (
                  // action placeholder required for footer nav
                  <div className="action-placeholder"></div>
                )}
                {isUpdate ? (
                  <div className="next-back-btn-container">
                    <Button id='root-cancel' dataAttribute='root-cancel-button' label="CANCEL" isDisable={data.disableNext} onClick={onEditCancel} />
                    <Button
                      id='root-save-changes'
                      dataAttribute='root-save-changes-button'
                      isloading={data.isRequestLoading}
                      label="SAVE CHANGES"
                      primary
                      isDisable={data.disableNext || data.isRequestLoading}
                      onClick={() => {
                        getThePageNumberAndSendToValidation();
                      }}
                    />
                  </div>
                ) : (
                  <div className="next-back-btn-container">
                    {page === 3 ||
                      (pathname.split('/')[1] !== 'welcome' && !isLaunchOffer && (
                        <Button
                          id='root-cancel-or-back'
                          dataAttribute='root-cancel-or-back-button'
                          label={isCopyOffer ? 'CANCEL' : 'BACK'}
                          isDisable={data.disableNext || (isCopyOffer && page === 6)}
                          onClick={() => {
                            if (!isCopyOffer) {
                              pathname === '/review-and-submit' ? navigate('/') : back()
                            } else {
                              dispatch(handleChange({ name: 'page', value: 0 }));
                              dispatch(setOfferStateToDefault());
                              navigate('/');
                            }
                          }}
                        />
                      ))}
                    {pathname.includes('/review-and-submit') ? (
                      <Button
                        id='root-submit'
                        dataAttribute='root-submit-button'
                        label={isCopyOffer ? 'SUBMIT COPY OF OFFER' : 'SUBMIT OFFER'}
                        primary
                        isDisable={
                          data.isRequestLoading || isRequestFailed.isFailed || disableSubmit
                        }
                        isloading={data.isRequestLoading}
                        onClick={handleSubmitOffer}
                      />
                    ) : (
                      <Button
                        id='root-start-building'
                        dataAttribute='root-start-building-button'
                        isloading={data.isRequestLoading}
                        label={
                          pathname === '/promotions'
                            ? 'Start Building'
                            : data.isRequestLoading
                            ? ''
                            : 'NEXT'
                        }
                        primary
                        isDisable={
                          data.disableNext ||
                          data.isRequestLoading ||
                          data.isHotelSpiritCodesLoading ||
                          isAttachmentLoading
                        }
                        onClick={() => {
                          isCopyOffer ? checkValidation(false, true) : checkValidation(true);
                        }}
                      />
                    )}
                  </div>
                )}
                {page > 0 && data.offers?.offerType !== '' && !isUpdate && offerId && !isLaunchOffer? (
                  isCopyOffer ? (
                    <div className="page-number">
                      Step {page === 2 ? 1 : page === 6 ? 2 : page === 7 ? 3 : 4} of 4
                    </div>
                  ) : (
                    <div className="page-number">
                      Step {page} of {pages.length}
                    </div>
                  )
                ) : (
                  <div className="action-placeholder"></div>
                )}
              </Actions>
            )}
          {pathname === '/view-data-services-task' &&
            !showRejectOfferScreen &&
            !showAcceptOfferScreen &&
            !showStepBackOfferScreen && (
              <Actions className="footer-nav">
                <Button
                  id='root-data-service-save-and-exit'
                  dataAttribute='root-data-service-save-and-exit-button'
                  label={'Save Draft & Exit'}
                  type="link"
                  data-testid="save-button"
                  className="save-btn"
                  onClick={onSaveOffer}
                  isloading={data.isSavingDraft}
                  isDisable={data.isSavingDraft}
                />
                <div className="next-back-btn-container">
                  {isBackRoutesValueExists && (
                    <Button id='root-step-back' dataAttribute='root-step-back-button' label="STEP BACK" onClick={onStepBackOffer} />
                  )}
                  <Button id='root-reject' dataAttribute='root-reject-button' label="REJECT" onClick={onRejectOffer} />
                  <Button
                    id='root-accept'
                    dataAttribute='root-accept-button'
                    label="ACCEPT"
                    primary
                    onClick={() => {
                      checkDataServiceValidation();
                    }}
                    isloading={data.isRequestLoading}
                    isDisable={data.isRequestLoading}
                  />
                </div>
                <div className="action-placeholder"></div>
              </Actions>
            )}
          {pathname === '/view-data-services-task' && showRejectOfferScreen && (
            <Actions className="footer-nav">
              <div className="action-placeholder"></div>
              <div className="next-back-btn-container">
                <Button id='root-data-service-cancel' dataAttribute='root-data-service-cancel-button' label="CANCEL" onClick={onCancelRejectOffer} className="save-btn" />
                <Button
                  id='root-data-service-reject-offer'
                  dataAttribute='root-data-service-reject-button'
                  label="REJECT OFFER"
                  primary
                  onClick={onConfirmRejectWithReason}
                  isloading={data.isRequestLoading}
                  isDisable={data.isRequestLoading}
                />
              </div>
              <div className="action-placeholder"></div>
            </Actions>
          )}
          {pathname === '/view-data-services-task' && showAcceptOfferScreen && (
            <Actions className="footer-nav">
              <div className="action-placeholder"></div>
              <div className="next-back-btn-container">
                <Button id='root-ds-service-cancel' dataAttribute='root-ds-service-cancel-offer-button' label="CANCEL" onClick={onCancelAcceptOffer} className="save-btn" />
                <Button
                  id='root-ds-service-accept-offer'
                  dataAttribute='root-ds-service-accept-offer-button'
                  label="ACCEPT OFFER"
                  primary
                  onClick={onConfirmAcceptOffer}
                  isloading={data.isRequestLoading}
                  isDisable={data.isRequestLoading}
                />
              </div>
              <div className="action-placeholder"></div>
            </Actions>
          )}
          {pathname === '/view-data-services-task' && showStepBackOfferScreen && (
            <Actions className="footer-nav">
              <div className="action-placeholder"></div>
              <div className="next-back-btn-container">
                <Button id='root-ds-step-back-cancel' dataAttribute='root-ds-step-back-cancel-button' label="CANCEL" onClick={onCancelStepBackOffer} className="save-btn" />
                <Button
                  id='root-ds-step-back'
                  dataAttribute='root-ds-step-back-button' 
                  label="STEP BACK OFFER"
                  primary
                  onClick={onConfirmStepBackOffer}
                  isloading={data.isRequestLoading}
                  isDisable={data.isRequestLoading || stepBackOptionLoading}
                />
              </div>
              <div className="action-placeholder"></div>
            </Actions>
          )}
        </div>
      </div>
    </div>
  );
};
